<template>
  <div class="page-container">
    <el-row class="row-item" v-for="i in length" :key="i">
      <el-col class="col-title" :span="4">{{ robotStatusData[(i-1)*length]?.groupName }}</el-col>
      <el-col class="col-cell" :style="robotStyles(robotStatusData[(i-1)*length+j-1]?.robotStatus)" v-for="j in length" :key="j" :span="5">
        {{ robotStatusData[(i-1)*length+j-1]?.currentNo }}
      </el-col>
    </el-row>
    <div class="lengend">
      <template v-for="item in robotStatusDict" :key="item.value">
        <span class="lengend-color" :style="robotStyles(item.robotStatus)"></span>
        <span class="lengend-item">{{ item.robotStatus }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { urlMap } from '@/common/config'
import { common } from "@/common/mixin"
import { reactive, toRefs, onMounted, onUnmounted } from "vue"
// import { ElMessage } from "element-plus";
// import { formatTime } from "../../common/utils";

const statusList = [
  {
    label: '运行',
    value: 1,
    bgColor: '#3B9113',
    color: '#FFFFFF'
  },
  {
    label: '暂停',
    value: 2,
    bgColor: '#F0E707',
    color: '#FFFFFF'
  },
  {
    label: '故障',
    value: 3,
    bgColor: '#D9001B',
    color: '#FFFFFF'
  },
  {
    label: '报警',
    value: 4,
    bgColor: 'transparent',
    color: '#FFFFFF'
  },
  {
    label: '信号弱',
    value: 5,
    bgColor: 'orange',
    color: '#FFFFFF'
  },
  {
    label: '空闲',
    value: 6,
    bgColor: '#1890FF',
    color: '#FFFFFF'
  },
  {
    label: '未开通数据监控',
    value: 7,
    bgColor: 'transparent',
    color: '#FFFFFF'
  }
]
export default {
  components: {
  },
  mixins: [common],
  setup() {
    const state = reactive({
      length: 4,
      robotStatusData: [],
      robotStatusDict: [],
      timer: null
    })
    const robotStyles = (type='') => {
      const find = statusList.find(item => item.label == type) || {}
      return {
        backgroundColor: find.bgColor, 
        color: find.color
      }
    }
    const getRobotStatusData = async () => {
      try {
        const url = urlMap.getBiRobotStatusInfo
        const res = await axios({
          url: url,
          method: 'POST',
          params: {
            configId: 1
          }
        })
        state.robotStatusData = res.data
        console.log('state.robotStatusData', state.robotStatusData)
      } catch (error) {
        console.error(error);
      }
    }
    const getRobotStatusDict = async () => {
      try {
        const url = urlMap.getBiRobotStatusDict
        const res = await axios({
          url: url,
          method: 'GET',
        })
        state.robotStatusDict = res.data || []
        console.log('state.robotStatusDict', state.robotStatusDict)
      } catch (error) {
        console.error(error);
      }
    }
    onMounted(() => {
      state.timer = setInterval(() => {
        getRobotStatusData()
        getRobotStatusDict()
      }, 5000)
    })
    onUnmounted(() => {
      clearInterval(state.timer)
    })
    return {
      ...toRefs(state),
      robotStyles,
    }
  },
}
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
}
.row-item {
  flex: 1;
}
.col-title {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-weight: bold;
}
.col-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFFFFF;
  // background-color: #3B9113;
  color: #FFFFFF;
  font-weight: bold;
}
.lengend {
  // height: 40px;
  // line-height: 40px;
  // font-size: 20px;
  margin: 10px;
  // padding: 10px;
  color: #FFFFFF;
  align-self: flex-start;
  .lengend-color {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    vertical-align: middle;
  }
  .lengend-item {
    display: inline-block;
    height: 14px;
    line-height: 14px;
    vertical-align: middle;
    padding: 0 6px;
    margin-right: 10px;
  }
}
</style>